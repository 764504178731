document.addEventListener("DOMContentLoaded", function () {
  const expandableButtons = document.querySelectorAll(".expandable-button");

  expandableButtons.forEach(function(button) {
    const contentId = button.getAttribute("data-content-id");
    const content = document.getElementById(contentId);

    if (content.scrollHeight > content.clientHeight) {
      button.classList.remove("hidden");
    }

    button.addEventListener("click", function () {
      if (content.classList.contains("line-clamp-6")) {
        content.classList.remove("line-clamp-6", "max-h-48");
        button.textContent = "Show Less";
      } else {
        content.classList.add("line-clamp-6", "max-h-48");
        button.textContent = "Read More";
      }
    });
  });
});
