const toast = document.getElementById("toast");
const toastTitle = document.getElementById("toastTitle");
const toastMessage = document.getElementById("toastMessage");
const hideToastBtn = document.getElementById("hideToastBtn");

let hideToastTimeout;

export const showToast = (title, message, borderColor = "#16a34a") => {
  clearTimeout(hideToastTimeout);
  toastTitle.innerText = title;
  toastMessage.innerText = message;
  toast.style.borderColor = borderColor;
  toast.classList.remove("hidden");
  toast.classList.add("flex");
  setTimeout(() => {
    toast.classList.remove("opacity-0");
    toast.classList.remove("translate-x-full");
  }, 10);
};

export const hideToast = () => {
  clearTimeout(hideToastTimeout);
  toast.classList.add("opacity-0");
  toast.classList.add("translate-x-full");
  setTimeout(() => {
    toast.classList.remove("flex");
    toast.classList.add("hidden");
  }, 300);
};

export const timeoutHideToast = (timeout = 3000) => {
  hideToastTimeout = setTimeout(() => {
    hideToast();
  }, timeout);
};

export const listenToHideToast = () => {
  if (!hideToastBtn) return;

  hideToastBtn.addEventListener("click", () => {
    hideToast();
  });
};
